
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tokenStatus: 'checking' as 'checking' | 'ok' | 'invalid' | 'already_used' | 'error',
  }),
  created() {
    this.checkEmailVerificationLink();
  },
  methods: {
    async checkEmailVerificationLink() {
      this.tokenStatus = 'checking';

      const responseData = await this.api({
        url: 'users/check_email_verification_link/',
        method: 'POST',
        json: {
          token: this.token,
        },
      });

      if (responseData.status === 200) {
        if (responseData.body.token_status === 'ok') {
          this.$store.commit('drfToken', responseData.body.drf_token);
          this.$store.commit('userData', responseData.body.user_data);
          this.$store.commit('loggedIn', true);
        }

        this.tokenStatus = responseData.body.token_status;
      } else {
        this.tokenStatus = 'error';
      }
    },
  },
});
