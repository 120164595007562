<template>
  <div class="horizontal-padding">
    <h1>Email Verification</h1>

    <spinner
      v-if="tokenStatus === 'checking'"
      preset="large"
    />

    <alert
      v-else-if="tokenStatus === 'ok'"
      variant="success"
    >
      Your email was successfully verified! Now let's
      <router-link
        :to="{ name: 'onboard' }"
        class="alert-link"
      >finish setting up your account</router-link>. <!-- eslint-disable-line vue/multiline-html-element-content-newline, max-len -->
    </alert>

    <alert
      v-else-if="tokenStatus === 'invalid'"
      variant="danger"
    >
      The email verification link was invalid.
    </alert>

    <alert v-else-if="tokenStatus === 'already_used'">
      The email verification link was already used. Please
      <router-link
        :to="{ name: 'login' }"
        class="alert-link"
      >log in</router-link>. <!-- eslint-disable-line vue/multiline-html-element-content-newline -->
    </alert>

    <template v-else-if="tokenStatus === 'error'">
      <alert variant="danger">
        Unable to communicate with the server. Please check your connection
        and try again.
      </alert>

      <div class="try-again-button-container">
        <button
          type="button"
          class="btn btn-outline-primary"
          @click="checkEmailVerificationLink"
        >
          Try Again
        </button>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tokenStatus: 'checking' as 'checking' | 'ok' | 'invalid' | 'already_used' | 'error',
  }),
  created() {
    this.checkEmailVerificationLink();
  },
  methods: {
    async checkEmailVerificationLink() {
      this.tokenStatus = 'checking';

      const responseData = await this.api({
        url: 'users/check_email_verification_link/',
        method: 'POST',
        json: {
          token: this.token,
        },
      });

      if (responseData.status === 200) {
        if (responseData.body.token_status === 'ok') {
          this.$store.commit('drfToken', responseData.body.drf_token);
          this.$store.commit('userData', responseData.body.user_data);
          this.$store.commit('loggedIn', true);
        }

        this.tokenStatus = responseData.body.token_status;
      } else {
        this.tokenStatus = 'error';
      }
    },
  },
});
</script>

<style scoped>
  .alert,
  .try-again-button-container {
    margin-right: auto;
    margin-left: auto;
    width: 350px;
    max-width: 100%;
  }
</style>
